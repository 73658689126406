import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { UserActions } from "../../../redux-slice/user/UserSlice";
import { PlantListActions } from "../../../redux-slice/plant/PlantListSlice";
import { AuthActions } from "../../../redux-slice/auth/AuthSlice";

// Utils
import { getIndustryUserFormSchema } from "../userUtils";
import { bcForUsersViewPage } from "../../../utils/BreadcrumbUtils";

// Components
import Loader from "../../../components/loader/Loader";
import Form from "../../../components/form/Form";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import UpdateUserPasswordForm from "../../../page-components/user/UpdateUserPasswordForm";

// Page Component
function PageHeaderSection({ orgId }) {
  // User Info Selector State
  const userInfo = useSelector((state) => state.user.userInfo);

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  const { name = "", orgType = "" } = orgInfo;
  const { firstName = "", lastName = "" } = userInfo;
  const userName = firstName + " " + lastName || "";

  return <PageHeader breadcrumbList={bcForUsersViewPage(orgId, name, userName, orgType)} className="mt-2" />;
}

function UserViewPageCard({ userInfo = {}, formInputItems, formValidationSchema, formSubmit }) {
  // User Info Selector State
  const userInfoLoading = useSelector((state) => state.user.userInfoLoading);

  // Update User Selector State
  const updateUserLoading = useSelector((state) => state.user.updateUserLoading);

  return (
    <div className="card shadow p-3">
      {/* Loader */}
      {userInfoLoading && <Loader containerClassName="m-5" />}

      {!userInfoLoading && (
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <span className="fw-bold">User Information</span>
          </div>

          {/*Form */}
          <Form
            loading={updateUserLoading}
            formInputItems={formInputItems}
            data={userInfo}
            formSubmit={formSubmit}
            formValidationSchema={formValidationSchema}
          />
        </div>
      )}
    </div>
  );
}

/**
 * User View Page
 */
export default function UserViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { orgId = "", userId = "" } = useParams();

  // Outlet Context
  const [setHeaderComponent = () => {}] = useOutletContext();

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Plant List Selector State
  const plantList = useSelector((state) => state.plantList.plantList);

  const userInfo = useSelector((state) => state.user.userInfo);

  const updateUserPasswordLoading = useSelector((state) => state.auth.updateUserPasswordLoading);

  // Organization type
  const { orgType = "" } = orgInfo || {};

  // User Information
  const { id = "", emailId = "" } = userInfo || {};

  // Formik onSubmit Function
  function formSubmit(userData) {
    dispatch(UserActions.updateUser({ userData: { orgId, ...userData }, userId }));
  }

  // useEffect
  useEffect(() => {
    dispatch(UserActions.getUserInfo({ userId }));
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
    dispatch(PlantListActions.getPlantList({ orgId, pageSize: 1000 }));
  }, [dispatch, userId, orgId]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection orgId={orgId} />);
  }, []);

  // use Memo
  const { formInputItems, formValidationSchema } = useMemo(() => {
    return getIndustryUserFormSchema(orgType, plantList);
  }, [plantList, orgId]);

  return (
    <div className="page-content">
      <div className="row">
        <div className="col-7">
          {/* User View Page Card */}
          <UserViewPageCard
            userInfo={userInfo}
            formInputItems={formInputItems}
            formValidationSchema={formValidationSchema}
            formSubmit={formSubmit}
          />
        </div>

        <div className="col-5">
          <div className="card shadow p-3">
            <h5>Update User Password</h5>

            <UpdateUserPasswordForm
              userId={id}
              emailId={emailId}
              loading={updateUserPasswordLoading}
              dispatchFn={AuthActions.updateUserPassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
